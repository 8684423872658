/**
 * Project lightbox functionality
 */

const $ = jQuery;

import '@fancyapps/fancybox';

export default {

	linkSelector: '.project-image',

	bind () {
		if(!document.querySelector('.project-image')) {
			return;
		}

		$(this.linkSelector).fancybox({
			margin: [44, 44],
			buttons: [
				'slideShow',
				'close',
			]
		})
	},

}
