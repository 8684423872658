/**
 * Careers functionality
 */

const $ = jQuery;

export default {

	/** @type {String} */
	navSelector: '#careers-nav',

	/** @type {String} */
	navItemSelector: '#careers-nav a:not([disabled])',

	/** @type {String} */
	disabledNavItemSelector: '#careers-nav a[disabled]',

	/** @type {String} */
	itemsWrapperSelector: '#careers-items',

	/** @type {String} */
	titleSelector: '.career-title',

	/** @type {String} */
	applyWrapperSelector: '#apply-wrapper',

	/**
	 * @type {Object}
	 * @link http://api.jqueryui.com/accordion/
	 */
	accordionArgs: {
		collapsible: true,
		active:      false,
		heightStyle: 'content'
	},

	/**
	 * jQuery bindings
	 */
	bind () {
		if(document.querySelector(this.itemsWrapperSelector)) {
			// setup accordion
			$(this.itemsWrapperSelector).accordion(this.accordionArgs);
		}

		if(document.querySelector(this.navItemSelector)) {
			// disabled navigation links
			$(this.disabledNavItemSelector).on('click', (e) => { e.preventDefault() });

			// navigation links
			$(this.navItemSelector).on('click', (e) => { this.filter(e) });
		};
	},

	/**
	 * Filter careers
	 *
	 * @param  {Event} e
	 */
	filter (e) {

		e.preventDefault();

		/** @type {String} filter key */
		let filter = $(e.currentTarget).data('filter');

		// close any open accordions
		$(this.itemsWrapperSelector).accordion('option', 'active', false);

		// if a filter is set
		if( filter ) {
			var self = this;

			// set nav active
			$(this.navItemSelector + '.active').removeClass('active');
			$(e.currentTarget).addClass('active');

			$(this.titleSelector + ':not([data-filter*="' + filter + '"]').slideUp();
			$(self.titleSelector + '[data-filter*="' + filter + '"]').slideDown();
		} else {
			// set nav active
			$(this.navItemSelector + '.active').removeClass('active');
			$(this.navItemSelector).first().addClass('active');

			// no filter is set, show all
			$(this.titleSelector).slideDown();
		}
	}
}
