/**
 * Project carousel
 */

import $ from 'jquery';

import 'slick-carousel';

export default {

	wrapperSelector: '#project-carousel',

	slideSelector: '.project-carousel-slide',

	slickOptions: {
		centerMode: true,
		slidesToShow: 1,
		centerPadding: '86px',
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		dotsClass: 'carousel-nav',
		pauseOnHover: false,

		responsive: [
			{
				breakpoint: 667,
				settings: {
					centerMod: false,
					centerPadding: null,

				}
			}
		]
	},

	/**
	 * jQuery bindings
	 */
	bind () {
		$(this.wrapperSelector).on('init', (e, slick) => { this.setArrowPosition(slick) });
		$(this.wrapperSelector).on('beforeChange', (e, slick, currentSlide, nextSlide) => { this.updateCarousel(e, slick, currentSlide, nextSlide) });
		$(this.wrapperSelector).slick(this.slickOptions);

		$(this.wrapperSelector).on('mouseenter.slick', (e) => { this.pause(e) });
		$(this.wrapperSelector).on('mouseleave.slick', (e) => { this.play(e) });
	},

	/**
	 * Update the carousel item classes to format them properly
	 *
	 * @param  {Event}      e
	 * @param  {Slick}      slick
	 * @param  {Int}        currentSlide current slide index
	 * @param  {Int}        nextSlide    next slide index
	 */
	updateCarousel (e, slick, currentSlide, nextSlide) {
		var offsetThreshold = $(slick.$slides[nextSlide]).offset().left;

		$(this.slideSelector).each( (index, slide) => {
			if(  $(slide).offset().left > offsetThreshold ) {
				$(slide).addClass('slide-offset');
			} else {
				$(slide).removeClass('slide-offset');
			}
		});
	},

	/**
	 * Set arrow position
	 *
	 * @param {Slick} slick
	 */
	setArrowPosition (slick) {
		let slideWidth  = $(slick.$slides[0]).outerWidth(),
			windowWidth = $(window).width(),
			offset      = (windowWidth - slideWidth) / 2;

		$('.slick-next').css({right: offset + 'px'});
		$('.slick-prev').css({left: offset + 'px'});
	},

	/**
	 * Pause the slideshow and its navigation
	 *
	 * @param  {Event} e
	 */
	pause (e) {
		$(this.wrapperSelector).slick('slickPause');
		$(this.wrapperSelector).addClass('paused');

	},

	/**
	 * Resume the slideshow and its navigation
	 *
	 * @param  {Event} e
	 */
	play (e) {
		$(this.wrapperSelector).slick('slickPlay');
		$(this.wrapperSelector).removeClass('paused');
	}

}
