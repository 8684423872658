<template>
    <div class="project-grid-item">
        <a :href="item.permalink">
            <img v-if="item.thumbnail" :src="item.thumbnail.src" :alt="item.title" />

            <div class="project-grid-item-title">
                <span>{{ item.title }}</span>
                <i></i>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object, required: true },
    },
};
</script>
