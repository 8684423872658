/**
 * Navigation functionality
 */

const $ = jQuery;

export default {
    /** @type {String} menu button selector */
    menuButtonSelector: '#menu-button',

    /** @type {String} navigation wrapper overlay selector */
    overlaySelector: '#nav-overlay',

    /** @type {String} class applied to body when nav is open */
    openClass: 'nav-open',

    /**
     * Bindings
     */
    bind() {
        $(this.menuButtonSelector).on('click', (e) => {
            this.toggle(e);
        });
        $(this.overlaySelector).bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
            this.maybeOffset();
        });
        $(window).scroll((e) => {
            this.maybeChangeHeader(e);
        });
    },

    /**
     * Toggle navigation visibility state
     *
     * @param  {Event} e
     */
    toggle(e) {
        $(e.currentTarget).toggleClass('is-active');
        $('body').toggleClass(this.openClass);

        $(this.overlaySelector).css({ top: 0 });
    },

    /**
     * Determine whether or not to move the overlay into the viewport
     * This functionality prevents accidental clicks on the nav overlay when it is not active while
     * still allowing the transitions to work as expected.
     */
    maybeOffset() {
        if (!$('body').hasClass(this.openClass)) {
            $(this.overlaySelector).css({ top: '-100%' });
        }
    },

    /**
     * Toggle the logo if necessary
     */
    maybeChangeHeader() {
        if ($(window).scrollTop() > 125) {
            $('body').addClass('scrolled');
        } else {
            $('body').removeClass('scrolled');
        }

        this.toggleHeader();
    },

    toggleHeader() {
        let threshold = document.body.scrollHeight - ($(window).height() + $('#primary-footer').height());

        if ($(window).scrollTop() > threshold) {
            $('body').addClass('overscrolled');
        } else {
            $('body').removeClass('overscrolled');
        }
    },
};
