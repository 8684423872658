/**
 * Medium-style loading functionality
 * This functionality mimics the image loading technique used on medium.com
 *
 * @url https://jmperezperez.com/medium-image-progressive-loading-placeholder/
 */

export default {

	/** @type {String} image wrapper selector */
	itemSelector: '.mload-image',

	/** @type {String} image preload thumbnail selector */
	preloadImageSelector: '.mload-image-preload',

	/** @type {Array} items */
	items: null,

	/**
	 * Initialize
	 */
	init () {
		var self = this;

		this.items = document.querySelectorAll(this.itemSelector);

		for( let index = 0; index < this.items.length; index++ ) {
			this.load( this.items[index], index );
		}
	},

	/**
	 * Load the image
	 *
	 * @param  {Node}    el    element node
	 * @param  {Integer} index element index
	 */
	load (el, index) {

		var smallImage = el.querySelector(this.preloadImageSelector),
			largeImage = new Image();

		largeImage.src = el.dataset.mload;
		largeImage.classList.add('mload-image-main');

		if(smallImage.getAttribute('alt')) {
			largeImage.setAttribute('alt', smallImage.getAttribute('alt'));
		}

		// if(smallImage.getAttribute('title')) {
			// largeImage.setAttribute('title', smallImage.getAttribute('title'));
		// }

		largeImage.onload = function() {
			el.classList.add('mload-image-loaded');
		}

		el.appendChild(largeImage);
	}
}