import Glide, { Autoplay, Breakpoints, Controls } from '@glidejs/glide/dist/glide.modular.esm';

export default {
    bind() {
        if (document.querySelector('#hero .glide')) {
            new Glide('#hero .glide', {
                autoplay: 7000,
            }).mount({ Autoplay, Controls, Breakpoints });
        }
    },
};
