/**
 * Featured expertise functionality
 */

export default {
	/** @type {String} featured expertise image selector */
	imageSelector: '.featured-expertise-image',

	/** @type {NodeList} link elements */
	linkEls: null,

	/** @type {String} active image class */
	activeClass: 'active',

	/**
	 * Initialization
	 */
	init() {
		this.linkEls = document.querySelectorAll('.featured-expertise-link');

		if(!this.linkEls) return;

		this.linkEls.forEach(el => {
			el.addEventListener('mouseover', e => this.toggle(e));
		});
	},

	/**
	 * Toggle the active image
	 *
	 * @param {Event} e
	 */
	toggle(e) {
		const slug = e.currentTarget.dataset.slug;
		const parent = e.currentTarget.closest('.featured-expertise-row');

		parent.querySelector(this.imageSelector + '.' + this.activeClass).classList.remove(this.activeClass);
		parent.querySelector(this.imageSelector + '[data-slug="' + slug + '"]').classList.add(this.activeClass);
	}
}