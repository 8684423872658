/**
 * Projects grid functionality
 */

import Vue from 'vue';
import ProjectsGrid from '../components/Projects/ProjectsGrid.vue';

export default {
    bind() {
        if (document.querySelector('#projects-grid')) {
            const app = new Vue({
                el: '#projects-grid',
                components: { ProjectsGrid },
            });
        }
    },

    init() {},
};
