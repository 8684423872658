<template>
    <section :class="wrapperClassList">
        <div class="projects-grid-inner">
            <div v-if="title" class="row big-title">
                <h1 class="col col-11 push-1" @click.prevent="hiddenNavClick">
                    {{ title }}
                </h1>
            </div>

            <ProjectsGridNav v-if="showNav" :filters="filters" v-model="currentFilters" :show-hidden="showHiddenNavItems" />

            <transition-group v-if="filteredItems.length" name="grid" tag="div" class="projects-grid-items">
                <ProjectsGridItem v-for="item in items" :item="item" :key="'project-' + item.id" v-show="filteredItems.includes(item.id)" />
            </transition-group>

            <div id="projects-grid-empty" v-else>
                <div>
                    <p>No projects available with the selected filters.</p>
                    <p><a href @click.prevent="clearFilters">Clear Filters</a></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProjectsGridNav from './ProjectsGridNav.vue';
import ProjectsGridItem from './ProjectsGridItem.vue';

const defaultFilters = { architecture: null, interiors: null, client_type: null, office: null, price: null };

export default {
    components: { ProjectsGridNav, ProjectsGridItem },

    props: {
        title: { type: String, default: null },
        filters: { type: Object, default: () => null },
        showNav: { type: Boolean, default: false },
        items: { type: Array, required: true },
        withBackground: { type: Boolean, default: true },
        contained: { type: Boolean, default: true },
    },

    data() {
        return {
            showHiddenNavItems: false,
            hiddenNavClicks: 0,
            hiddenNavInterval: null,
            currentFilters: defaultFilters,
        };
    },

    computed: {
        wrapperClassList() {
            const list = [];

            if (this.withBackground) {
                list.push('section-tan', 'section-gradient-top');
            }

            if (this.contained) {
                list.push('projects-grid-contained');
            }

            return list;
        },

        filtering() {
            return !!Object.values(this.currentFilters).filter((val) => !!val).length;
        },

        filteredItems() {
            let items = [...this.items];

            if (!this.filtering) {
                return items.map((i) => i.id);
            }

            for (const [key, value] of Object.entries(this.currentFilters)) {
                if (value) {
                    if (value == '*') {
                        items = items.filter((item) => item.filters[key].length > 0);
                    } else {
                        items = items.filter((item) => item.filters[key].includes(value));
                    }
                }
            }

            return items.map((i) => i.id);
        },
    },

    created() {
        this.setFiltersFromUrl();
    },

    methods: {
        /**
         * Set the initial filters from the url hash.
         * e.g. /projects/#interiors:restaurant
         */
        setFiltersFromUrl() {
            const { hash } = window.location;

            if (hash) {
                const hashParts = hash.replace('#', '').split(':');

                if (hashParts.length == 2) {
                    const filter = hashParts[0];

                    this.currentFilters[filter] = this.getFilterItemIdFromSlug(filter, hashParts[1]);
                }
            }
        },

        /**
         * Get a filter item id from a slug.
         *
         * @param {String} filter
         * @param {String} slug
         */
        getFilterItemIdFromSlug(filter, slug) {
            const items = this.filters[filter].items;

            if (items) {
                const item = items.find((item) => item.slug == slug);

                if (item) {
                    return item.value;
                }
            }

            return null;
        },

        clearFilters() {
            for (const [key, value] of Object.entries(defaultFilters)) {
                this.currentFilters[key] = null;
            }
        },

        hiddenNavClick() {
            if (this.showHiddenNavItems) {
                this.showHiddenNavItems = false;
            } else {
                this.clearHiddenNavInterval();

                this.hiddenNavClicks++;

                if (this.hiddenNavClicks == 3) {
                    this.showHiddenNavItems = true;
                    this.hiddenNavClicks = 0;
                }

                this.hiddenNavInterval = window.setInterval(() => {
                    this.hiddenNavClicks = 0;
                    this.clearHiddenNavInterval();
                }, 1500);
            }
        },

        clearHiddenNavInterval() {
            if (this.hiddenNavInterval) {
                this.hiddenNavInterval = window.clearInterval(this.hiddenNavInterval);
            }
        },
    },
};
</script>

<style scoped>
h1 {
    user-select: none;
}

.grid-enter-active,
.grid-leave-active {
    transition: all 0.25s;
}

.grid-enter,
.grid-leave-to {
    opacity: 0;
    background-color: #f5f2ee !important;
    transform: scale(0.9);
}
</style>
