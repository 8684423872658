const $ = jQuery;

export default {
    bind() {
        $('a.contact-office').click(this.setOffice);
    },

    setOffice(e) {
        const $inputs = $('input[value="' + e.currentTarget.dataset.office + '"]');
        $inputs.prop('checked', true);
    },
};
