<template>
    <div id="grid-nav" class="row">
        <vue-select
            v-for="(filter, key) in filters"
            class="grid-nav-item"
            :components="{ OpenIndicator }"
            :class="{ 'grid-nav-item-selected': !!value[key] }"
            v-model="value[key]"
            :key="'filter-' + key"
            :options="getFilterOptions(key, filter)"
            :placeholder="filter.label"
            :searchable="false"
            :clearable="false"
            :append-to-body="true"
            :reduce="(item) => item.value"
            :calculate-position="withPopper"
            v-show="!filter.hidden || (filter.hidden && showHidden)"
        ></vue-select>
    </div>
</template>

<script>
import VueSelect from 'vue-select';
import { createPopper } from '@popperjs/core';

export default {
    components: { VueSelect },

    props: {
        value: { type: Object, required: true },
        filters: { type: Object, required: true },
        showHidden: { type: Boolean, default: false },
    },

    data() {
        return {
            placement: 'bottom',
            OpenIndicator: {
                render: (createElement) => this.$createElement('i', { class: ['fa fa-caret-down'] }),
            },
        };
    },

    methods: {
        getFilterOptions(key, filter) {
            let filters = [...filter.items];

            if (filter.show_all) {
                filters = [{ label: 'All ' + filter.label, value: '*' }, ...filters];
            }

            return [{ label: filter.label, value: null }, ...filters];
        },

        withPopper(dropdownList, component) {
            dropdownList.style.width = 'auto';

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: { offset: [0, -1] },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top');
                        },
                    },
                ],
            });

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy();
        },
    },
};
</script>
