/**
 * Frontend script entry point
 */

// import 'babel-polyfill';

import '../styles/abel.scss';

const $ = jQuery;

import Hero from './modules/hero';
import Nav from './modules/nav';
import ProjectCarousel from './modules/project-carousel';
import ProjectGrid from './modules/project-grid';
import ProjectMap from './modules/project-map';
import ProjectLightbox from './modules/project-lightbox';
import Careers from './modules/careers';
import Forms from './abel-forms';
import Mload from './modules/image-mload';

import FeaturedExpertise from './modules/featured-expertise';

/**
 * Main application objects
 * @type {Object}
 */
var adg = {
    /**
     * Each jquery module should have a "bind" method for binding to events that are only available
     * after the DOM is ready
     *
     * @type {Array}
     */
    jqueryModules: [Hero, Nav, ProjectCarousel, ProjectGrid, ProjectMap, ProjectLightbox, Careers, Forms],

    /**
     * Attach module jQuery bindings
     */
    bind() {
        for (let module of this.jqueryModules) {
            module.bind();
        }
    },
};

/**
 * Fire when ready
 */
$(() => {
    adg.bind();
});

ProjectGrid.init();

window.onload = function () {
    Mload.init();
};

// new jquery-less functionality
window.addEventListener('DOMContentLoaded', (e) => {
    FeaturedExpertise.init();
});
