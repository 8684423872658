/**
 * Project map functionality
 */

import $ from 'jquery';

export default {

	mapSelector: '#map',

	map: {
		width: 0,
		height: 0
	},

	bind () {
		this.mapSize();

		$('.lat-lng').each((index, item) => { this.positionMarker(item); });
	},

	mapSize () {
		this.map.width = $(this.mapSelector).width();
		this.map.height = $(this.mapSelector).height();
	},

	positionMarker (item) {
		console.log( 'positioning marker', item );

		let lat = $(item).data('lat');
		let lng = $(item).data('lng');

		// console.log( 'lat: ', lat );

		let left = ( (this.map.width / 360.0) * (180 + lng) );
		let top  = ( (this.map.height / 180.0) * (90 - lat) );

		$(item).css({
			left: left,
			top: top
		})

// 		int x =  (int) ((MAP_WIDTH/360.0) * (180 + lon));
// int y =  (int) ((MAP_HEIGHT/180.0) * (90 - lat));
	}

}